import {
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import {
  CustomVendorContainer,
  DeliveryAreaFeeInputTextField,
  ViewStatusBlock,
} from "../../Utils/VendorStyledTags";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosVendorServiceInstance } from "../../../../services/api";
import { toast } from "react-toastify";
import DeliveryAreaIsNotUniqueMsg from "../Section/DeliveryAreaIsNotUniqueMsg";
import DeleteIcon from "@mui/icons-material/RemoveCircle";
import AddRoundedIcon from "@mui/icons-material/AddCircle";

export const VendorManageDeiveryAreaFeeScreen = ({ areasAndDeliveryFee }) => {
  const isLargeScreen = useMediaQuery("(min-width:1069px)");
  const isMediumScreen = useMediaQuery(
    "(min-width:700px) and (max-width:1069px)"
  );

  const isBoth = isLargeScreen || isMediumScreen;
  const location = useLocation();
  const navigate = useNavigate();

  const convertToObjectArray = (obj) => {
    return Object.entries(obj).map(([area, details]) => ({
      area,
      price: details.price,
    }));
  };
  const [areasAndDeliveryFees, setAreasAndDeliveryFees] = useState(
    convertToObjectArray(
      isBoth
        ? areasAndDeliveryFee && Object.keys(areasAndDeliveryFee).length > 0
          ? areasAndDeliveryFee
          : { "": { price: "" } }
        : location.state && Object.keys(location.state).length > 0
        ? location.state
        : { "": { price: "" } }
    )
  );

  const [isAreaNotUniqueDialogOpen, setIsAreaNotUniqueDialogOpen] =
    useState(false);

  const confimationStatusDataForAreaNotUnique = {
    isDialogOpen: true,
    dialogHeading: "Area Already Exists",
    dialogContent:
      "The area you are trying to add already exists in the system. Please review the list of existing areas or enter a new area name.",
  };

  const handleClickBackIcon = () => {
    navigate("/vendor/vendorService");
  };

  const handleAreaChange = (e, index) => {
    const newAreasAndDeliveryFees = [...areasAndDeliveryFees];
    newAreasAndDeliveryFees[index].area = e.target.value;
    setAreasAndDeliveryFees(newAreasAndDeliveryFees);
  };

  const handleFeeChange = (e, index) => {
    const newAreasAndDeliveryFees = [...areasAndDeliveryFees];
    const value = e.target.value;

    const formattedValue = value.match(/^\d*\.?\d{0,2}/)?.[0] || "";

    newAreasAndDeliveryFees[index].price = formattedValue;
    setAreasAndDeliveryFees(newAreasAndDeliveryFees);
  };

  const handleDeleteDeliveryArea = (index) => {
    const newAreasAndDeliveryFees = areasAndDeliveryFees.filter(
      (_, i) => i !== index
    );
    setAreasAndDeliveryFees(newAreasAndDeliveryFees);
  };

  const handleAddDeliveryArea = () => {
    setAreasAndDeliveryFees([...areasAndDeliveryFees, { area: "", price: "" }]);
  };

  const handleSaveChanges = async () => {
    areasAndDeliveryFees.map(
      (areaAndFee) => (areaAndFee.area = areaAndFee.area.trim())
    );

    const areaCounts = areasAndDeliveryFees.reduce((acc, { area }) => {
      if (area) {
        acc[area] = (acc[area] || 0) + 1;
      }
      return acc;
    }, {});

    const duplicateAreas = Object.entries(areaCounts)
      .filter(([area, count]) => count > 1)
      .map(([area]) => area);

    if (duplicateAreas.length > 0) {
      setIsAreaNotUniqueDialogOpen(true);
      return;
    }

    const result = areasAndDeliveryFees.reduce((acc, { area, price }) => {
      if (area) {
        const priceValue = price ? Number(price) : 0;
        acc[area] = { price: priceValue };
      }
      return acc;
    }, {});

    try {
      const response = await axiosVendorServiceInstance.post(
        `/vendor-service/change-area-delivery-fee?userId=${JSON.parse(
          localStorage.getItem("userId")
        )}`,
        result
      );

      const vendor = JSON.parse(localStorage.getItem("user"));
      if (Object.keys(response.data.data).length === 0) {
        vendor.deliveryFee = null;
      } else {
        vendor.deliveryFee = response.data.data;
      }

      localStorage.setItem("user", JSON.stringify(vendor));

      navigate("/vendor/vendorUpdatingAreaFeeSuccess");
      setTimeout(() => {
        if (isBoth) {
          navigate("/vendor/vendorDeliveryConfigurations");
        } else {
          navigate("/vendor/vendorService");
        }
      }, 1500);
    } catch (err) {
      if (err.response === undefined) {
        alert("Please Try Again Later!");
      } else {
        const errmessage = err.response.data.error;
        toast.error(errmessage);
      }
    }
  };

  return (
    <>
      <CssBaseline />
      <main>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "0px 12px",
            background: "#FFF",
            height: "auto",
            position: "relative",
          }}
        >
          <CustomVendorContainer style={{ background: "#FFF", height: "100%" }}>
            <Container maxWidth="sm" style={{ padding: "0px", height: "100%" }}>
              <ViewStatusBlock
                style={{
                  justifyContent: isBoth ? "space-between" : "center",
                  paddingBottom: "0px",
                  margin: "0px 0px",
                  position: "relative",
                }}
              >
                {!isBoth && (
                  <IconButton
                    onClick={handleClickBackIcon}
                    color="inherit"
                    sx={{ position: "absolute", top: isBoth ? 10 : 0, left: 0 }}
                  >
                    <ArrowBackIosIcon />
                  </IconButton>
                )}

                <Typography
                  style={{
                    fontWeight: isBoth ? 600 : 500,
                    color: "#1C1C1C",
                    fontFamily: "Poppins",
                    fontSize: isBoth ? "20px" : "18px",
                    textAlign: "left",
                    lineHeight: "130%",
                    textTransform: "capitalize",
                    marginTop: "10px",
                  }}
                >
                  Delivery area{" "}
                </Typography>
              </ViewStatusBlock>{" "}
              <Container maxWidth="sm" style={{ padding: "0px" }}>
                <Container
                  style={{
                    marginTop: isBoth ? "20px" : "40px",
                    padding: "0px",
                  }}
                >
                  <Typography
                    style={{
                      color: "#4B5563",
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "normal",
                      textAlign: "left",
                      marginBottom: "20px",
                    }}
                  >
                    Configure your delivery area and delivery charge to
                    streamline your delivery process.
                  </Typography>
                  {areasAndDeliveryFees &&
                    areasAndDeliveryFees.map(({ area, price }, index) => (
                      <Container
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          gap: "16px",
                          padding: "0px",
                        }}
                      >
                        <DeliveryAreaFeeInputTextField
                          placeholder="Enter Area"
                          type="text"
                          variant="outlined"
                          value={area}
                          onChange={(e) => handleAreaChange(e, index)}
                        />

                        <DeliveryAreaFeeInputTextField
                          placeholder="Enter Delivery Price"
                          type="number"
                          variant="outlined"
                          sx={{
                            "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                              {
                                WebkitAppearance: "none",
                                margin: 0,
                              },
                            "input[type=number]": {
                              MozAppearance: "textfield",
                            },
                          }}
                          inputProps={{ min: 0 }}
                          value={price}
                          onChange={(e) => handleFeeChange(e, index)}
                          required
                        />
                        {index === areasAndDeliveryFees.length - 1 ? (
                          <IconButton onClick={handleAddDeliveryArea}>
                            <AddRoundedIcon
                              sx={{ color: "#059669", fontSize: "48px" }}
                            />
                          </IconButton>
                        ) : (
                          <IconButton
                            onClick={() => handleDeleteDeliveryArea(index)}
                          >
                            <DeleteIcon
                              sx={{
                                color: "var(--gray-800, #1F2937)",
                                fontSize: "48px",
                              }}
                            />
                          </IconButton>
                        )}
                      </Container>
                    ))}

                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                      height: isBoth ? "80vh" : "68vh",
                      paddingBottom: "20px",
                    }}
                  >
                    <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Button
                        fullWidth
                        variant="contained"
                        sx={{
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          fontWeight: "400",
                          padding: "13px 10px",
                          backgroundColor: "#059669",
                          borderRadius: "8px",
                          textTransform: "capitalize",
                          "&:hover": { backgroundColor: "#046c4e" },
                        }}
                        onClick={handleSaveChanges}
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                  </Grid>
                </Container>
              </Container>
              {/* {!isBoth && <VendorBottomNavbar clickedIndex={2} />} */}
            </Container>

            <DeliveryAreaIsNotUniqueMsg
              confimationStatusData={confimationStatusDataForAreaNotUnique}
              statusChangeState={[
                isAreaNotUniqueDialogOpen,
                setIsAreaNotUniqueDialogOpen,
              ]}
            />
          </CustomVendorContainer>
        </Box>
      </main>
    </>
  );
};
