import React, { useEffect } from "react";
import Loader from "./layouts/Loader.jsx";
import { useMediaQuery } from "@mui/material";
import "./App.css";
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import UserProvider from "./contexts/UserContext.js";
import vendorOrderCancelled from "./assets/VendorOrders/vendorOrderCancelled.svg";
import { ThemeProvider } from "@mui/material";
import { lazy, Suspense } from "react";
import { useThemeConfig } from "./themeConfig";
import { lazily } from "react-lazily";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import VendorRoutes from "./contexts/vendorRoutes.js";

const VendorEditProfileDetailsSuccessScreen = lazy(() =>
  import(
    "./components/Vendor/Profile/Screen/VendorEditProfileDetailsSuccessScreen.jsx"
  )
);

const AddVendorInfo = lazy(() =>
  import("./components/Vendor/authentication/AddVendorInfo.jsx")
);
const AddCustomerInfo = lazy(() =>
  import("./components/Customer/authentication/AddCustomerInfo.jsx")
);
const SignInOTP = lazy(() => import("./components/Customer/SignInOTP.jsx"));
const AdminSignIn = lazy(() =>
  import("./components/Admin/authentication/AdminSignIn.jsx")
);
const ViewVendors = lazy(() => import("./components/Admin/ViewVendors.jsx"));
const ViewVendorContactNumberChangeRequests = lazy(() =>
  import("./components/Admin/ViewVendorContactNumberChangeRequests.jsx")
);
const ViewVendorDeleteAccountRequests = lazy(() =>
  import("./components/Admin/ViewVendorDeleteAccountRequests.jsx")
);
const MyCart = lazy(() => import("./components/Customer/MyCart.jsx"));
const OrderConfirmation = lazy(() =>
  import("./components/Customer/OrderConfirmation.jsx")
);
const OrderSummary = lazy(() =>
  import("./components/Customer/OrderSummary.jsx")
);
const PaymentSummary = lazy(() =>
  import("./components/Customer/PaymentSummary.jsx")
);
const PaymentSuccessMsg = lazy(() =>
  import("./components/Customer/PaymentSuccessMsg.jsx")
);
const PaymentFailureMsg = lazy(() =>
  import("./components/Customer/PaymentFailureMsg.jsx")
);
const PaymentOption = lazy(() =>
  import("./components/Customer/PaymentOption.jsx")
);
const AddNewDeliveryAddress = lazy(() =>
  import("./components/Customer/AddNewDeliveryAddress.jsx")
);
const DeliveryAddress = lazy(() =>
  import("./components/Customer/DeliveryAddress.jsx")
);
const CartProvider = lazy(() => import("./contexts/CartContext.js"));
const SigninVendorViaContactNumber = lazy(() =>
  import("./components/Vendor/authentication/SigninVendorViaContactNumber.jsx")
);
const SigninVendorViaEmail = lazy(() =>
  import("./components/Vendor/authentication/SigninVendorViaEmail.jsx")
);
const SignupVendorViaEmail = lazy(() =>
  import("./components/Vendor/authentication/SignupVendorViaEmail.jsx")
);
const AddVendorAddress = lazy(() =>
  import("./components/Vendor/authentication/AddVendorAddress.jsx")
);
const AddOwnerInfo = lazy(() =>
  import("./components/Vendor/authentication/AddOwnerInfo.jsx")
);
const VendorInfo = lazy(() => import("./components/Customer/VendorInfo.jsx"));
const VendorSearch = lazy(() =>
  import("./components/Customer/VendorSearch.jsx")
);
const PrivateRoute = lazy(() => import("./components/Utils/PrivateRoute.js"));
const ItemInfo = lazy(() => import("./components/Customer/ItemInfo.jsx"));
const CustomerSigninSuccess = lazy(() =>
  import("./components/Customer/authentication/CustomerSigninSuccess.jsx")
);
const VendorSigninSuccess = lazy(() =>
  import("./components/Vendor/authentication/VendorSigninSuccess.jsx")
);
const VendorStatusPendingScreen = lazy(() =>
  import("./components/Vendor/authentication/VendorStatusPendingScreen.jsx")
);
const AddNewDeliveryAddressDialog = lazy(() =>
  import("./components/Customer/AddNewAddressDialog.jsx")
);

const VendorOfferSuccessMessageScreen = lazy(() =>
  import(
    "./components/Vendor/Offers/Screen/VendorOfferSuccessMessageScreen.jsx"
  )
);
const VendorAllOffersScreen = lazy(() =>
  import("./components/Vendor/Offers/Screen/VendorAllOffersScreen.jsx")
);
const VendorCreateOfferScreen1 = lazy(() =>
  import("./components/Vendor/Offers/Screen/VendorCreateOfferScreen1.jsx")
);
const VendorCreateOfferScreen2 = lazy(() =>
  import("./components/Vendor/Offers/Screen/VendorCreateOfferScreen2.jsx")
);
const VendorCreateOfferAddItems = lazy(() =>
  import("./components/Vendor/Offers/Screen/VendorCreateOfferAddItems.jsx")
);
const VendorViewPreviewScreen = lazy(() =>
  import("./components/Vendor/Offers/Screen/VendorViewPreviewScreen.jsx")
);
const VendorPreviewScreen = lazy(() =>
  import("./components/Vendor/Offers/Screen/VendorPreviewScreen.jsx")
);

const VendorItemSuccessMessageScreen = lazy(() =>
  import("./components/Vendor/Items/Screen/VendorItemSuccessMessageScreen.jsx")
);

const VendorAllItemsScreen = lazy(() =>
  import("./components/Vendor/Items/Screen/VendorAllItemsScreen.jsx")
);
const VendorViewItemScreen = lazy(() =>
  import("./components/Vendor/Items/Screen/VendorViewItemScreen.jsx")
);
const VendorCreateItemScreen1 = lazy(() =>
  import("./components/Vendor/Items/Screen/VendorCreateItemScreen1.jsx")
);
const VendorAllOrders = lazy(() =>
  import("./components/Vendor/Orders/Screen/VendorAllOrders.jsx")
);
const VendorSummary = lazy(() =>
  import("./components/Vendor/Orders/Screen/VendorSummary.jsx")
);
const VendorLastNOrdersByStatus = lazy(() =>
  import("./components/Vendor/Orders/Screen/VendorLastNOrdersByStatus.jsx")
);
const { ServiceMainScreen } = lazily(() =>
  import("./components/Vendor/Services/Screen/ServiceMainScreen.jsx")
);

const { DeliveryRestrictionTime } = lazily(() =>
  import("./components/Vendor/Services/Screen/DeliveryRestrictionTime.jsx")
);
const { VendorUpdatingTime } = lazily(() =>
  import("./components/Vendor/Services/Screen/VendorUpdatingTime.jsx")
);
const VendorUpdateServiceSuccessMessage = lazy(() =>
  import(
    "./components/Vendor/Services/Screen/VendorUpdateServiceSuccessMessage.jsx"
  )
);
const VendorProfileMainScreen = lazy(() =>
  import("./components/Vendor/Profile/Screen/VendorProfileMainScreen.jsx")
);

const VendorOrderCancelledOrCompletedSuccessScreen = lazy(() =>
  import(
    "./components/Vendor/Orders/Screen/VendorOrderCancelledOrCompletedSuccessScreen.jsx"
  )
);
const VendorUpdateOrderStatus = lazy(() =>
  import("./components/Vendor/Orders/Screen/VendorUpdateOrderStatus.jsx")
);
const { ErrorPageTemplete } = lazily(() =>
  import("./components/ErrorPages/ErrorPageTemplete.jsx")
);
const { MaintenanceScreen } = lazily(() =>
  import("./components/ErrorPages/MaintenanceScreen.jsx")
);
const VendorStatusScreenTemplete = lazy(() =>
  import("./components/Vendor/authentication/VendorStatusScreenTemplete.jsx")
);
const ViewOffersForVendor = lazy(() =>
  import("./components/Customer/Offers/ViewOffersForVendor.jsx")
);
const CustomerAllPlacedOrders = lazy(() =>
  import("./components/Customer/TrackOrders/Screen/CustomerAllPlacedOrders.jsx")
);
const CustomerPlacedOrderProgressDetail = lazy(() =>
  import(
    "./components/Customer/TrackOrders/Screen/CustomerPlacedOrderProgressDetail.jsx"
  )
);
const CustomerAddOrderReview = lazy(() =>
  import("./components/Customer/TrackOrders/Screen/CustomerAddOrderReview.jsx")
);

const { LandingPageMainScreen } = lazily(() =>
  import("./components/LandingPageV2/Screens/LandingPageMainScreen.jsx")
);
const { PrivacyPolicyScreen } = lazily(() =>
  import("./components/LandingPageV2/Screens/PrivacyPolicyScreen.jsx")
);
const { TermsAndConditions } = lazily(() =>
  import("./components/LandingPageV2/Screens/TermsAndConditions.jsx")
);
const { AboutUs } = lazily(() =>
  import("./components/LandingPageV2/Screens/AboutUs.jsx")
);
const { Careers } = lazily(() =>
  import("./components/LandingPageV2/Screens/Careers.jsx")
);
const { NotAvailableNow } = lazily(() =>
  import("./components/ErrorPages/NotAvailableNow.jsx")
);
const { AddVariantsAndOptions } = lazily(() =>
  import("./components/Vendor/Items/Screen/AddVariantsAndOptions.jsx")
);
const { PriceAddingScreen } = lazily(() =>
  import("./components/Vendor/Items/Screen/PriceAddingScreen.jsx")
);
const { PreviewScreen } = lazily(() =>
  import("./components/Vendor/Items/Screen/PreviewScreen.jsx")
);
const { VendorManageDeiveryAreaFeeScreen } = lazily(() =>
  import(
    "./components/Vendor/Services/Screen/VendorManageDeiveryAreaFeeScreen.jsx"
  )
);
const VendorAvailableDeliveryAreaAndFee = lazy(() =>
  import("./components/Customer/VendorAvailableDeliveryAreaAndFee.jsx")
);
const AddVendorLogoAndURL = lazy(() =>
  import("./components/Vendor/authentication/AddVendorLogoAndURL.jsx")
);
const VendorProfileManageAccount = lazy(() =>
  import("./components/Vendor/Profile/Screen/VendorProfileManageAccount.jsx")
);
const { VendorProfileEditDetails } = lazily(() =>
  import("./components/Vendor/Profile/Screen/VendorProfileEditDetails.jsx")
);
const { ReorderItemCategoryName } = lazily(() =>
  import("./components/Vendor/Items/Screen/ReorderItemCategoryName.jsx")
);
const { VendorManageBankDetails } = lazily(() =>
  import("./components/Vendor/Services/Screen/VendorManageBankDetails.jsx")
);
const { VendorManageDigitalPaymentMethods } = lazily(() =>
  import(
    "./components/Vendor/Services/Screen/VendorManageDigitalPaymentMethods.jsx"
  )
);
const CredentialsConfiguration = lazy(() =>
  import(
    "./components/Vendor/Services/Screen/CreateOrUpdatePMConfiguration.jsx"
  )
);
const { UploadPaymentProof } = lazily(() =>
  import("./components/Customer/UploadPaymentProof.jsx")
);

const { VendorUpgradeSubscriptionScreen } = lazily(() =>
  import(
    "./components/Vendor/Subscription/Screen/FreeToPro/VendorUpgradeSubscriptionScreen.jsx"
  )
);
const { VendorSubscriptionPlanDetails } = lazily(() =>
  import(
    "./components/Vendor/Subscription/Screen/FreeToPro/VendorSubscriptionPlanDetails.jsx"
  )
);
const { VendorManageSubscriptionScreen } = lazily(() =>
  import(
    "./components/Vendor/Subscription/Screen/VendorManageSubscriptionScreen.jsx"
  )
);
const { VendorViewAvailablePlansScreen } = lazily(() =>
  import(
    "./components/Vendor/Subscription/Screen/ManagePlan/VendorViewAvailablePlansScreen.jsx"
  )
);
const { VendorChangeSubscriptionPlanScreen } = lazily(() =>
  import(
    "./components/Vendor/Subscription/Screen/ManagePlan/VendorChangeSubscriptionPlanScreen.jsx"
  )
);
const { VendorCancelSubscriptionScreen } = lazily(() =>
  import(
    "./components/Vendor/Subscription/Screen/ManagePlan/VendorCancelSubscriptionScreen.jsx"
  )
);
const { VendorManagePaymentHomeScreen } = lazily(() =>
  import(
    "./components/Vendor/Subscription/Screen/ManagePayments/VendorManagePaymentHomeScreen.jsx"
  )
);
const { VendorViewCardDetailsScreen } = lazily(() =>
  import(
    "./components/Vendor/Subscription/Screen/ManagePayments/VendorViewCardDetailsScreen.jsx"
  )
);
const { VendorViewReceiptScreen } = lazily(() =>
  import(
    "./components/Vendor/Subscription/Screen/BillHistory/VendorViewReceiptScreen.jsx"
  )
);
const { VendorViewPaymentHistory } = lazily(() =>
  import(
    "./components/Vendor/Subscription/Screen/BillHistory/VendorViewPaymentHistory.jsx"
  )
);
const { VendorPlanDetails } = lazily(() =>
  import("./components/Vendor/authentication/VendorPlanDetails.jsx")
);
const { VendorSubscriptionPlanSelection } = lazily(() =>
  import(
    "./components/Vendor/authentication/VendorSubscriptionPlanSelection.jsx"
  )
);
const { VendorSubscriptionPlanSelector } = lazily(() =>
  import(
    "./components/Vendor/authentication/VendorSubscriptionPlanSelector.jsx"
  )
);
const { MarketingMainScreen } = lazily(() =>
  import("./components/Vendor/Profile/Screen/MarketingMainScreen.jsx")
);
const { Promotion } = lazily(() =>
  import("./components/Vendor/Profile/Screen/Promotion.jsx")
);
const { QRCodeGenerator } = lazily(() =>
  import("./components/Vendor/Profile/Screen/QRCodeGenerator.jsx")
);
const { VendorChangePaymentCard } = lazily(() =>
  import(
    "./components/Vendor/Subscription/Screen/PaymentIssueFix/VendorChangePaymentCard.jsx"
  )
);
const SigninCustomerViaEmail = lazy(() =>
  import("./components/Customer/authentication/SigninCustomerViaEmail.jsx")
);
const { ItemCreationOption } = lazily(() =>
  import("./components/Vendor/Items/Screen/ItemCreationOption.jsx")
);
const { CreateNewItemUnitBased } = lazily(() =>
  import("./components/Vendor/Items/Screen/Grocery/CreateNewItemUnitBased.jsx")
);
const { UnitBasedItemReviewConfirm } = lazily(() =>
  import(
    "./components/Vendor/Items/Screen/Grocery/UnitBasedItemReviewConfirm.jsx"
  )
);
const VendorShortcutToEditDeactivate = lazily(() =>
  import("./components/Vendor/Items/Screen/VendorShortcutToEditDeactivate.jsx")
);
const { PreferencesScreen } = lazily(() =>
  import("./components/Vendor/Profile/Screen/PreferencesScreen.jsx")
);
const { Storefront } = lazily(() =>
  import("./components/Vendor/Profile/Screen/Storefront.jsx")
);
const { Appearance } = lazily(() =>
  import("./components/Vendor/Profile/Screen/Appearance.jsx")
);
const { CurrencyPrefScreen } = lazily(() =>
  import("./components/Vendor/Profile/Screen/CurrencyPrefScreen.jsx")
);
const SelectStoreType = lazy(() =>
  import("./components/Vendor/authentication/SelectStoreType.jsx")
);
const DeliveryForm = lazy(() =>
  import("./components/Vendor/Services/Screen/DeliveryForm.jsx")
);
const VendorAvaiDeliveryAreaAndTime = lazy(() =>
  import("./components/Customer/VendorAvaiDeliveryAreaAndTime.jsx")
);
const { VendorNotifyDelayDelivery } = lazily(() =>
  import("./components/Vendor/Services/Screen/VendorNotifyDelayDelivery.jsx")
);
const ViewVendorPaymentScreen = lazy(() =>
  import("./components/Admin/ViewVendorPaymentScreen.jsx")
);
const CustomerProfileMain = lazy(() =>
  import("./components/Customer/Profile/Screen/CustomerProfileMain.jsx")
);

const CustomerProfileSignIn = lazy(() =>
  import("./components/Customer/Profile/Screen/CustomerProfileSignIn.jsx")
);

const CustomerViewAllAddress = lazy(() =>
  import("./components/Customer/Profile/Screen/CustomerViewAllAddress.jsx")
);

const { AddAvailabilityAndPriceScreenForRetail } = lazily(() =>
  import(
    "./components/Vendor/Items/Retail/Screen/AddAvailabilityAndPriceScreenForRetail.jsx"
  )
);
const { CreateRetailBasedItemScreen1 } = lazily(() =>
  import(
    "./components/Vendor/Items/Retail/Screen/CreateRetailBasedItemScreen1.jsx"
  )
);
const { AddVariantsForRetailScreen } = lazily(() =>
  import(
    "./components/Vendor/Items/Retail/Screen/AddVariantsForRetailScreen.jsx"
  )
);
const { AddOptionsForRetailScreen } = lazily(() =>
  import(
    "./components/Vendor/Items/Retail/Screen/AddOptionsForRetailScreen.jsx"
  )
);
const { VendorPreviewProductDetailForRetailScreen } = lazily(() =>
  import(
    "./components/Vendor/Items/Retail/Screen/VendorPreviewProductDetailForRetailScreen.jsx"
  )
);
const VendorViewRetailItemScreen = lazy(() =>
  import(
    "./components/Vendor/Items/Retail/Screen/VendorViewRetailItemScreen.jsx"
  )
);

const { TimeFormatSelectionScreen } = lazily(() =>
  import("./components/Vendor/Profile/Screen/TimeFormatSelectionScreen.jsx")
);

const POSHomeScreen = lazy(() =>
  import("./components/Vendor/POS/Screen/POSHomeScreen.jsx")
);
const POSCartScreen = lazy(() =>
  import("./components/Vendor/POS/Screen/POSCartScreen.jsx")
);
const POSCheckoutScreen1 = lazy(() =>
  import("./components/Vendor/POS/Screen/POSCheckoutScreen1.jsx")
);
const POSCheckoutScreen2 = lazy(() =>
  import("./components/Vendor/POS/Screen/POSCheckoutScreen2.jsx")
);
const POSInvoiceGeneratedSuccessScreen = lazy(() =>
  import("./components/Vendor/POS/Screen/POSInvoiceGeneratedSuccessScreen.jsx")
);

const ExportOrdersAsPdf = lazy(() =>
  import("./components/Vendor/Orders/Screen/ExportOrdersAsPdf.jsx")
);

const ExportPdfFieldsSelection = lazy(() =>
  import("./components/Vendor/Orders/Screen/ExportPdfFieldsSelection.jsx")
);

const PreparingPdfInProgressScreen = lazy(() =>
  import("./components/Vendor/Orders/Screen/PreparingPdfInProgressScreen.jsx")
);

const PdfGenerationSuccessOrFailureScreen = lazy(() =>
  import(
    "./components/Vendor/Orders/Screen/PdfGenerationSuccessOrFailureScreen.jsx"
  )
);

const Restaurant = lazy(() =>
  import("./components/LandingPageV2/Screens/Restaurant.jsx")
);

const Retail = lazy(() =>
  import("./components/LandingPageV2/Screens/Retail.jsx")
);

const Grocery = lazy(() =>
  import("./components/LandingPageV2/Screens/Grocery.jsx")
);

const SubscriptionConfirmation = lazy(() =>
  import("./components/Vendor/authentication/SubscriptionConfirmation.jsx")
);

export const preloadComponents = (components) => {
  components.forEach((component) => import(`${component}`));
};

const userType = JSON.parse(localStorage.getItem("userType"));
const user = JSON.parse(localStorage.getItem("user"));
const token = Cookies.get("FEASTO_Token");

const SecureRoute = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const userType = JSON.parse(localStorage.getItem("userType"));
  const user = JSON.parse(localStorage.getItem("user"));
  const token = Cookies.get("FEASTO_Token");

  useEffect(() => {
    if (
      location.pathname.startsWith("/vendor") &&
      (!user || !token || userType !== "Vendor")
    ) {
      navigate("/auth/loginVendor/email");
    } else {
      const paths = [
        "/customer/orderConfirmation",
        "/customer/orderSummary",
        "/customer/paymentOption",
        "/customer/paymentSummary",
      ];
      const isSecuredPath = paths.some((path) =>
        location.pathname.startsWith(path)
      );
      if (isSecuredPath && (!token || !user)) {
        navigate("/auth/loginCustomer/email");
      }
    }
  }, [location, token, userType, navigate]);

  if (
    location.pathname.startsWith("/vendor") &&
    (!token || userType !== "Vendor")
  ) {
    // Returning spinner to prevent rendering the children if the conditions are not met
    return <Loader />;
  }

  return children;
};

function App() {
  const { theme, handleColorChange, globalColor } = useThemeConfig();
  const userType = JSON.parse(localStorage.getItem("userType"));
  const isLargeScreen = useMediaQuery("(min-width:1069px)");
  const isMediumScreen = useMediaQuery(
    "(min-width:700px) and (max-width:1069px)"
  );
  const isBoth = isLargeScreen || isMediumScreen;

  // useEffect(() => {
  //   const preloadComponents = () => {
  //     import("./components/Vendor/Profile/Screen/VendorProfileManageAccount.jsx");
  //     import("./components/Vendor/Profile/Screen/VendorProfileEditDetails.jsx");
  //     import("./components/Vendor/Profile/Screen/MarketingMainScreen.jsx");
  //     import("./components/Vendor/Profile/Screen/Promotion.jsx");
  //     import("./components/Vendor/Profile/Screen/QRCodeGenerator.jsx");
  //     import("./components/Vendor/Profile/Screen/PreferencesScreen.jsx");
  //     import("./components/Vendor/Profile/Screen/Appearance.jsx");
  //     import("./components/Vendor/Profile/Screen/CurrencyPrefScreen.jsx");
  //   };
  //   preloadComponents();
  // }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <Suspense fallback={<Loader />}>
            <SecureRoute>
              <Routes>
                <Route
                  path="/auth/loginCustomer/email"
                  element={
                    <UserProvider>
                      <SigninCustomerViaEmail />
                    </UserProvider>
                  }
                />
                <Route
                  path="/auth/loginVendor/email"
                  element={
                    <UserProvider>
                      <SigninVendorViaEmail />
                    </UserProvider>
                  }
                />
                <Route
                  path="/auth/signupVendor/email"
                  element={
                    <UserProvider>
                      <SignupVendorViaEmail />
                    </UserProvider>
                  }
                />
                <Route
                  path="/auth/loginVendor/contactNumber"
                  element={
                    <UserProvider>
                      <SigninVendorViaContactNumber />
                    </UserProvider>
                  }
                />
                <Route
                  path="/auth/selectStoreType"
                  element={<SelectStoreType />}
                />
                <Route
                  path="/auth/otp"
                  element={
                    <UserProvider>
                      <SignInOTP onColorChange={handleColorChange} />
                    </UserProvider>
                  }
                />
                <Route
                  path="/auth/addCustomer"
                  element={
                    <UserProvider>
                      <AddCustomerInfo />
                    </UserProvider>
                  }
                />
                <Route
                  path="/auth/customerSuccess"
                  element={
                    <CustomerSigninSuccess tickSymbolcolor={"#059669"} />
                  }
                />
                <Route
                  path="/auth/addVendor"
                  element={
                    <UserProvider>
                      <AddVendorInfo />
                    </UserProvider>
                  }
                />
                <Route
                  path="/auth/addLocation"
                  element={<AddVendorAddress />}
                />
                <Route path="/auth/addOwnerInfo" element={<AddOwnerInfo />} />
                <Route
                  path="/auth/addVendorLogoAndURL"
                  element={<AddVendorLogoAndURL />}
                />
                <Route
                  path="/auth/vendorSuccess"
                  element={<VendorSigninSuccess />}
                />
                <Route
                  path="/vendor/vendorPendingStatus"
                  element={<VendorStatusPendingScreen />}
                />
                <Route
                  path="/vendor/vendorStatus"
                  element={
                    <VendorStatusScreenTemplete
                      mainMessage={"Sorry! Account Suspended"}
                      secondaryMessage={
                        "It looks like your account has been suspended. Please contact our support team for assistance and to resolve the issue."
                      }
                      // tickSymbolcolor={"#059669"}
                      // navigateBackRoute={"/vendor/vendorAllItems"}
                    />
                  }
                />
                <Route path="/auth/loginAdmin" element={<AdminSignIn />} />
                <Route
                  path="/admin/vendors"
                  element={
                    <ViewVendors
                      limit={10}
                      title={"VENDORS"}
                      isPendingOnly={false}
                    />
                  }
                />
                <Route
                  path="/admin/ViewVendorPaymentScreen"
                  element={
                    <ViewVendorPaymentScreen
                      limit={10}
                      title={"VENDORS"}
                      isPendingOnly={false}
                    />
                  }
                />
                <Route
                  path="/admin/newVendors"
                  element={
                    <ViewVendors
                      limit={10}
                      title={"NEW VENDOR REQUEST"}
                      isPendingOnly={true}
                    />
                  }
                />
                <Route
                  path="/admin/vendorRequestsDeleteAccount"
                  element={
                    <ViewVendorDeleteAccountRequests
                      limit={10}
                      title={"Delete Vendor Request"}
                      vendorRequest={"DELETE_ACCOUNT"}
                    />
                  }
                />
                <Route
                  path="/admin/vendorRequestsContactNumberChange"
                  element={
                    <ViewVendorContactNumberChangeRequests
                      limit={10}
                      title={"Vendor Contact Number Change Request"}
                      vendorRequest={"CHANGE_CONTACT_NUMBER"}
                    />
                  }
                />
                <Route
                  path={"/:vendorUrlFormatted"}
                  element={
                    <UserProvider>
                      <CartProvider>
                        <VendorInfo
                          onColorChange={handleColorChange}
                          themeColor={globalColor}
                        />
                      </CartProvider>
                    </UserProvider>
                  }
                />
                <Route path="/*" element={<VendorRoutes />} />
                <Route
                  path="/customer/itemInfo"
                  element={
                    <CartProvider>
                      <ItemInfo themeColor={globalColor} />
                    </CartProvider>
                  }
                />
                <Route
                  path="/customer/myCart"
                  element={
                    <UserProvider>
                      <CartProvider>
                        <MyCart />
                      </CartProvider>
                    </UserProvider>
                  }
                />
                <Route
                  path="/customer/vendorAvailableDeliveryAreas"
                  element={
                    <CartProvider>
                      <UserProvider>
                        <VendorAvailableDeliveryAreaAndFee />
                      </UserProvider>
                    </CartProvider>
                  }
                />
                <Route
                  path="/customer/VendorAvaiDeliveryAreaAndTime"
                  element={
                    <CartProvider>
                      <UserProvider>
                        <VendorAvaiDeliveryAreaAndTime
                          themeColor={globalColor}
                        />
                      </UserProvider>
                    </CartProvider>
                  }
                />
                <Route
                  path="/customer/orderConfirmation"
                  element={
                    <CartProvider>
                      <UserProvider>
                        <OrderConfirmation themeColor={globalColor} />
                      </UserProvider>
                    </CartProvider>
                  }
                />
                <Route
                  path="/customer/orderSummary"
                  element={
                    <CartProvider>
                      <OrderSummary />
                    </CartProvider>
                  }
                />
                <Route
                  path="/customer/paymentSummary"
                  element={
                    <CartProvider>
                      <PaymentSummary />
                    </CartProvider>
                  }
                />
                <Route
                  path="/customer/home/search"
                  element={<VendorSearch />}
                />
                <Route
                  element={
                    <CartProvider>
                      <AddNewDeliveryAddressDialog />
                    </CartProvider>
                  }
                />
                <Route
                  path="/customer/paymentOption"
                  element={
                    <CartProvider>
                      <UserProvider>
                        <PaymentOption globalColor={globalColor} />
                      </UserProvider>
                    </CartProvider>
                  }
                />
                <Route
                  path="/customer/paymentProof"
                  element={<UploadPaymentProof />}
                />
                <Route
                  path="/customer/paymentSuccess"
                  element={<PaymentSuccessMsg />}
                />
                <Route
                  path="/customer/paymentFailure"
                  element={<PaymentFailureMsg />}
                />
                <Route
                  path="/customer/addDeliveryAddress"
                  element={
                    <CartProvider>
                      <AddNewDeliveryAddress />
                    </CartProvider>
                  }
                />
                <Route
                  path="/customer/deliveryAddress"
                  element={
                    <CartProvider>
                      <UserProvider>
                        <DeliveryAddress globalColor={globalColor} />
                      </UserProvider>
                    </CartProvider>
                  }
                />
                <Route
                  path="/customer/offers"
                  element={<ViewOffersForVendor />}
                />
                <Route
                  path="/vendor/vendorOfferSuspendSuccess"
                  element={
                    <VendorOfferSuccessMessageScreen
                      mainMessage={" Offer Suspended"}
                      secondaryMessage={
                        "The offer has been suspended and is no longer available for customers."
                      }
                      navigateBackRoute={"/vendor/vendorAllOffers"}
                      tickSymbolcolor={"#111827"}
                    />
                  }
                />
                <Route
                  path="/vendor/vendorOfferReactivationSuccess"
                  element={
                    <VendorOfferSuccessMessageScreen
                      mainMessage={"Offer Reactivated"}
                      secondaryMessage={
                        "The offer has been reactivated and is now available for customers."
                      }
                      navigateBackRoute={"/vendor/vendorAllOffers"}
                      tickSymbolcolor={"#059669"}
                    />
                  }
                />
                <Route
                  path="/vendor/vendorOfferCreationSuccess"
                  element={
                    <VendorOfferSuccessMessageScreen
                      mainMessage={"Offer Created"}
                      secondaryMessage={
                        "Your new offer has been successfully created and is now available for customers."
                      }
                      navigateBackRoute={"/vendor/vendorAllOffers"}
                      tickSymbolcolor={"#059669"}
                    />
                  }
                />
                <Route
                  path="/vendor/vendorOfferUpdationSuccess"
                  element={
                    <VendorOfferSuccessMessageScreen
                      mainMessage={"Offer Updated"}
                      secondaryMessage={
                        "The offer has been successfully updated with your changes."
                      }
                      navigateBackRoute={"/vendor/vendorAllOffers"}
                      tickSymbolcolor={"#059669"}
                    />
                  }
                />
                <Route
                  path="/vendor/vendorAllOffers"
                  element={<VendorAllOffersScreen themeColor={globalColor} />}
                />
                <Route
                  path="/vendor/vendorCreateOffer1"
                  element={<VendorCreateOfferScreen1 isDisabled={false} />}
                />
                <Route
                  path="/vendor/vendorCreateOffer2"
                  element={<VendorCreateOfferScreen2 isDisabled={false} />}
                />
                <Route
                  path="/vendor/vendorCreateOfferAddItems"
                  element={
                    <VendorCreateOfferAddItems themeColor={globalColor} />
                  }
                />
                <Route
                  path="/vendor/vendorViewPreviewOffer"
                  element={<VendorViewPreviewScreen />}
                />
                <Route
                  path="/vendor/vendorPreviewOffer"
                  element={<VendorPreviewScreen />}
                />
                <Route
                  path="/vendor/vendorItemActivateSuccess"
                  element={
                    <VendorItemSuccessMessageScreen
                      mainMessage={"Item reactivated"}
                      secondaryMessage={
                        "The item has been successfully reactivated and is now available in your menu. Happy selling!"
                      }
                      tickSymbolcolor={"#059669"}
                      navigateBackRoute={"/vendor/vendorAllItems"}
                    />
                  }
                />
                <Route
                  path="/vendor/vendorItemDeactivateSuccess"
                  element={
                    <VendorItemSuccessMessageScreen
                      mainMessage={"Item Deactivated"}
                      secondaryMessage={
                        "Your item has been deactivated. You can reactivate it anytime to make it available for orders again."
                      }
                      tickSymbolcolor={"#111827"}
                      navigateBackRoute={"/vendor/vendorAllItems"}
                    />
                  }
                />
                <Route
                  path="/vendor/vendorItemDeleteSuccess"
                  element={
                    <VendorItemSuccessMessageScreen
                      mainMessage={"Item Deleted"}
                      secondaryMessage={
                        "Your item has been permanently deleted. If this was a mistake, please add the item again."
                      }
                      tickSymbolcolor={"#EF4444"}
                      navigateBackRoute={"/vendor/vendorAllItems"}
                    />
                  }
                />
                <Route
                  path="/vendor/vendorItemCreationSuccess"
                  element={
                    <VendorItemSuccessMessageScreen
                      mainMessage={"Item Created"}
                      secondaryMessage={
                        "Your new item has been successfully created and added to your menu."
                      }
                      tickSymbolcolor={"#059669"}
                      navigateBackRoute={"/vendor/vendorAllItems"}
                    />
                  }
                />
                <Route
                  path="/vendor/vendorItemUpdateSuccess"
                  element={
                    <VendorItemSuccessMessageScreen
                      mainMessage={"Item Updated"}
                      secondaryMessage={
                        "The item has been successfully updated with your changes."
                      }
                      tickSymbolcolor={"#059669"}
                      navigateBackRoute={"/vendor/vendorAllItems"}
                    />
                  }
                />
                <Route
                  path="/vendor/vendorItemUpdateSuccess"
                  element={
                    <VendorItemSuccessMessageScreen
                      mainMessage={"Item Updated"}
                      secondaryMessage={
                        "The new menu item has been successfully updated with your changes."
                      }
                      tickSymbolcolor={"#059669"}
                      navigateBackRoute={"/vendor/vendorAllItems"}
                    />
                  }
                />
                <Route
                  path="/vendor/vendorAllItems"
                  element={<VendorAllItemsScreen globalColor={globalColor} />}
                />
                <Route
                  path="/vendor/reorderItemCategoryName"
                  element={<ReorderItemCategoryName themeColor={globalColor} />}
                />
                <Route
                  path="/vendor/vendorViewItem"
                  element={<VendorViewItemScreen themeColor={globalColor} />}
                />
                <Route
                  path="/vendor/vendorCreateItem1"
                  element={
                    <VendorCreateItemScreen1
                      isDisabled={false}
                      themeColor={globalColor}
                    />
                  }
                />
                <Route
                  path="/vendor/AddVariantsAndOptions"
                  element={<AddVariantsAndOptions themeColor={globalColor} />}
                />
                <Route
                  path="/vendor/addPrices"
                  element={<PriceAddingScreen themeColor={globalColor} />}
                />
                <Route
                  path="/vendor/preview"
                  element={<PreviewScreen themeColor={globalColor} />}
                />
                <Route
                  path="/vendor/allOrders"
                  element={<VendorAllOrders themeColor={globalColor} />}
                />
                <Route
                  path="/vendor/ordersByStatus"
                  element={
                    <VendorLastNOrdersByStatus themeColor={globalColor} />
                  }
                />
                <Route
                  path="/vendor/vendorSummary"
                  element={<VendorSummary themeColor={globalColor} />}
                />
                <Route
                  path="/vendor/vendorService"
                  element={<ServiceMainScreen />}
                />
                {/* <Route
                  path="/vendor/vendorDeliveryConfigurations"
                  element={<DeliveryConfigurations themeColor={globalColor} />}
                /> */}
                <Route
                  path="/vendor/vendorDeliveryRestrictionTime"
                  element={<DeliveryRestrictionTime />}
                />
                <Route
                  path="/vendor/vendorUpdatingDeliveryRestrictionTimeSuccess"
                  element={
                    <VendorUpdateServiceSuccessMessage
                      mainMessage={"Success"}
                      secondaryMessage={
                        "Your Delivery Restriction Time has been Updated!"
                      }
                      tickSymbolcolor={"#059669"}
                      navigateBackRoute={
                        isBoth
                          ? "/vendor/vendorDeliveryConfigurations"
                          : "/vendor/vendorService"
                      }
                    />
                  }
                />
                <Route
                  path="/vendor/vendorManageDeliveryAreaFee"
                  element={<VendorManageDeiveryAreaFeeScreen />}
                />
                <Route
                  path="/vendor/DeliveryAreaFee"
                  element={<DeliveryForm themeColor={globalColor} />}
                />
                {/* <Route
                  path="/vendor/vendorManageDispatchMode"
                  element={<VendorManageDispatchMode />}
                /> */}
                <Route
                  path="/vendor/vendorNotifyDelayDelivery"
                  element={<VendorNotifyDelayDelivery />}
                />
                <Route
                  path="/vendor/vendorDelayMessageSentSuccess"
                  element={
                    <VendorUpdateServiceSuccessMessage
                      mainMessage={"Delay Message Sent"}
                      secondaryMessage={
                        "Your customers have received your message!"
                      }
                      tickSymbolcolor={"#059669"}
                      navigateBackRoute={
                        isBoth
                          ? "/vendor/vendorNotifyDelayDelivery"
                          : "/vendor/vendorService"
                      }
                    />
                  }
                />
                <Route
                  path="/vendor/vendorUpdatingTime"
                  element={<VendorUpdatingTime />}
                />
                {/* <Route
                  path="/vendor/vendorManagePaymentMethod"
                  element={
                    <VendorManagePaymentMethodScreen themeColor={globalColor} />
                  }
                /> */}
                <Route
                  path="/vendor/vendorManageBankDetails"
                  element={<VendorManageBankDetails />}
                />
                <Route
                  path="/vendor/digitalPaymentMethods"
                  element={<VendorManageDigitalPaymentMethods />}
                />
                <Route
                  path="/vendor/configurePaymentMethod"
                  element={<CredentialsConfiguration />}
                />
                <Route
                  path="/vendor/vendorUpdatingTimeSuccess"
                  element={
                    <VendorUpdateServiceSuccessMessage
                      mainMessage={"Time Updated"}
                      secondaryMessage={
                        "Your Store opening and closing time has been updated!"
                      }
                      tickSymbolcolor={"#059669"}
                      navigateBackRoute={
                        isBoth
                          ? "/vendor/ServiceOpeningHours"
                          : "/vendor/vendorService"
                      }
                    />
                  }
                />
                <Route
                  path="/vendor/vendorUpdatingAreaFeeSuccess"
                  element={
                    <VendorUpdateServiceSuccessMessage
                      mainMessage={"Success"}
                      secondaryMessage={
                        "Your Delivery area and delivery price has been Updated!"
                      }
                      tickSymbolcolor={"#059669"}
                      navigateBackRoute={
                        isBoth
                          ? "/vendor/vendorDeliveryConfigurations"
                          : "/vendor/vendorService"
                      }
                    />
                  }
                />
                <Route
                  path="/vendor/vendorUpdatingBankDetailsSuccess"
                  element={
                    <VendorUpdateServiceSuccessMessage
                      mainMessage={"Success"}
                      secondaryMessage={"Your Bank Details  has been Updated!"}
                      tickSymbolcolor={"#059669"}
                      navigateBackRoute={
                        isBoth
                          ? "/vendor/vendorManagePaymentMethod"
                          : "/vendor/vendorService"
                      }
                    />
                  }
                />
                <Route
                  path="/vendor/vendorUpdatingPOSChargesSuccess"
                  element={
                    <VendorUpdateServiceSuccessMessage
                      mainMessage={"Success"}
                      secondaryMessage={
                        "Your Additional POS Charges have been Updated!"
                      }
                      tickSymbolcolor={"#059669"}
                      navigateBackRoute={
                        isBoth
                          ? "/vendor/vendorPOSAdditionalCharges"
                          : "/vendor/vendorService"
                      }
                    />
                  }
                />
                <Route
                  path="/vendor/vendorUpdatingBankDetailsSuccess"
                  element={
                    <VendorUpdateServiceSuccessMessage
                      mainMessage={"Success"}
                      secondaryMessage={"Your Bank Details has been Updated!"}
                      tickSymbolcolor={"#059669"}
                      navigateBackRoute={"/vendor/vendorService"}
                    />
                  }
                />
                <Route
                  path="/vendor/vendorOrderCancelledSuccess"
                  element={
                    <VendorOrderCancelledOrCompletedSuccessScreen
                      mainMessage={"Order Cancelled!"}
                      secondaryMessage={
                        "The order has been cancelled. Payment successfully refunded."
                      }
                      vendorSuccessImg={vendorOrderCancelled}
                      navigateBackRoute={"/vendor/allOrders"}
                    />
                  }
                />
                <Route
                  path="/vendor/vendorOrderCompletedSuccess"
                  element={
                    <VendorOrderCancelledOrCompletedSuccessScreen
                      mainMessage={"Order Completed!"}
                      secondaryMessage={
                        "The order has been delivered, items verified, and payment successfully received."
                      }
                      navigateBackRoute={"/vendor/allOrders"}
                    />
                  }
                />
                <Route
                  path="/vendor/vendorProfile"
                  element={<VendorProfileMainScreen />}
                />
                <Route
                  path="/vendor/manageAccount"
                  element={<VendorProfileManageAccount />}
                />
                <Route
                  path="/vendor/preferencesScreen"
                  element={<PreferencesScreen />}
                />
                <Route path="/vendor/storefront" element={<Storefront />} />
                <Route
                  path="/vendor/appearance"
                  element={<Appearance onColorChange={handleColorChange} />}
                />
                <Route
                  path="/vendor/currencyPreferences"
                  element={<CurrencyPrefScreen themeColor={globalColor} />}
                />
                <Route
                  path="/vendor/upgradeSubscription"
                  element={<VendorUpgradeSubscriptionScreen />}
                />
                <Route
                  path="/vendor/subscriptionPlanDetails"
                  element={<VendorSubscriptionPlanDetails />}
                />
                <Route
                  path="/auth/subscriptionPlanDetails"
                  element={<VendorPlanDetails />}
                />
                <Route
                  path="/auth/subscribeToPro"
                  element={<VendorSubscriptionPlanSelection />}
                />
                VendorSubscriptionPlanSelector
                <Route
                  path="/auth/subscriptionPlanSelector"
                  element={<VendorSubscriptionPlanSelector />}
                />
                {/* <Route
                      path="/vendor/upgradeSubscriptionSuccessful"
                      element={<VendorManageSubscriptionSuccessScreen
                        navigateTo="/vendor/allOrders"
                        title="Plan changed"
                        message="Your plan is successfully changed"
                        />}
                    />

                    <Route
                      path="/vendor/upgradeSubscriptionFailed"
                      element={<VendorManageSubscriptionFailureScreen
                        navigateToIfClose="/vendor/vendorProfile"
                        navigateToIfTryAgain="/vendor/subscriptionPlanDetails"
                        title="Payment Failed"
                        message="Oops! We can't change your plan right now due to a payment
                        error. Please check your payment details and try again"
                      
                        />}
                    /> */}
                <Route
                  path="/vendor/manageSubscription"
                  element={<VendorManageSubscriptionScreen />}
                />
                <Route
                  path="/vendor/availablePlans"
                  element={<VendorViewAvailablePlansScreen />}
                />
                <Route
                  path="/vendor/changeSubscriptionPlan"
                  element={<VendorChangeSubscriptionPlanScreen />}
                />
                <Route
                  path="/vendor/cancelSubscription"
                  element={<VendorCancelSubscriptionScreen />}
                />
                <Route
                  path="/vendor/managePayments"
                  element={<VendorManagePaymentHomeScreen />}
                />
                <Route
                  path="/vendor/viewCardDetails"
                  element={<VendorViewCardDetailsScreen />}
                />
                <Route
                  path="/vendor/viewReceipt"
                  element={<VendorViewReceiptScreen />}
                />
                <Route
                  path="/vendor/viewPaymentHistory"
                  element={<VendorViewPaymentHistory />}
                />
                <Route
                  path="/vendor/selectPaymentCard"
                  element={<VendorChangePaymentCard />}
                />
                <Route
                  path="/vendor/editDetails"
                  element={<VendorProfileEditDetails />}
                />
                <Route
                  path="/vendor/vendorEditProfileSuccess"
                  element={
                    <VendorEditProfileDetailsSuccessScreen
                      mainMessage={"Profile Edited"}
                      tickSymbolcolor={"#059669"}
                      navigateBackRoute={"/vendor/manageAccount"}
                    />
                  }
                />
                <Route
                  path="/vendor/updateOrderStatus/:orderId"
                  element={<VendorUpdateOrderStatus themeColor={globalColor} />}
                />
                <Route path="/auth/authentication" element={<PrivateRoute />} />
                <Route path="/" element={<LandingPageMainScreen />} />
                <Route
                  path="/home/:section"
                  element={<LandingPageMainScreen />}
                />
                <Route
                  path="/home/privacyPolicy"
                  element={<PrivacyPolicyScreen />}
                />
                <Route
                  path="/home/termsConditions"
                  element={<TermsAndConditions />}
                />
                AboutUs
                <Route path="/home/aboutUs" element={<AboutUs />} />
                <Route path="/home/careers" element={<Careers />} />
                <Route path="/feasto/error" element={<ErrorPageTemplete />} />
                <Route
                  path="/feasto/maintenance"
                  element={<MaintenanceScreen />}
                />
                <Route
                  path="/feasto/notAvailableNow"
                  element={<NotAvailableNow />}
                />
                <Route
                  path="/customer/allPlacedOrders"
                  element={
                    <UserProvider>
                      <CustomerAllPlacedOrders themeColor={globalColor} />
                    </UserProvider>
                  }
                />
                <Route
                  path="/customer/placedOrderProgress"
                  element={
                    <UserProvider>
                      <CustomerPlacedOrderProgressDetail />
                    </UserProvider>
                  }
                />
                <Route
                  path="/customer/addOrderReview"
                  element={
                    <UserProvider>
                      <CustomerAddOrderReview themeColor={globalColor} />
                    </UserProvider>
                  }
                />
                <Route
                  path="/vendor/MarketingMainScreen"
                  element={
                    <UserProvider>
                      <MarketingMainScreen />
                    </UserProvider>
                  }
                />
                <Route
                  path="/vendor/Promotion"
                  element={
                    <UserProvider>
                      <Promotion themeColor={globalColor} />
                    </UserProvider>
                  }
                />
                <Route
                  path="/vendor/QRCodeGenerator"
                  element={
                    <UserProvider>
                      <QRCodeGenerator />
                    </UserProvider>
                  }
                />
                <Route
                  path="/vendor/ItemCreationOption"
                  element={
                    <UserProvider>
                      <ItemCreationOption themeColor={globalColor} />
                    </UserProvider>
                  }
                />
                <Route
                  path="/vendor/CreateNewItemUnitBased"
                  element={
                    <UserProvider>
                      <CreateNewItemUnitBased themeColor={globalColor} />
                    </UserProvider>
                  }
                />
                <Route
                  path="/vendor/UnitBasedItemReviewConfirm"
                  element={
                    <UserProvider>
                      <UnitBasedItemReviewConfirm themeColor={globalColor} />
                    </UserProvider>
                  }
                />
                <Route
                  path="/vendor/VendorShortcutToEditDeactivate"
                  element={
                    <UserProvider>
                      <VendorShortcutToEditDeactivate />
                    </UserProvider>
                  }
                />
                <Route
                  path="/vendor/vendorAllOffers"
                  element={<VendorAllOffersScreen themeColor={globalColor} />}
                />
                {/* <Route
                  path="/vendor/ServiceOpeningHours"
                  element={
                    <UserProvider>
                      <ServiceOpeningHours />
                    </UserProvider>
                  }
                /> */}
                <Route
                  path="/vendor/vendorCreateRetailBasedItem1"
                  element={<CreateRetailBasedItemScreen1 isDisabled={false} />}
                />
                <Route
                  path="/vendor/AddVariantsForRetail"
                  element={<AddVariantsForRetailScreen />}
                />
                <Route
                  path="/vendor/AddOptionsForRetail"
                  element={<AddOptionsForRetailScreen />}
                />
                <Route
                  path="/vendor/AddAvailabilityAndPriceScreenForRetail"
                  element={<AddAvailabilityAndPriceScreenForRetail />}
                />
                <Route
                  path="/vendor/previewProductDetailForRetail"
                  element={<VendorPreviewProductDetailForRetailScreen />}
                />
                <Route
                  path="/vendor/vendorViewRetailItem"
                  element={<VendorViewRetailItemScreen />}
                />
                <Route
                  path="/customer/CustomerProfileMain"
                  element={
                    <UserProvider>
                      <CustomerProfileMain themeColor={globalColor} />
                    </UserProvider>
                  }
                />
                <Route
                  path="/customer/CustomerProfileSignIn"
                  element={
                    <UserProvider>
                      <CustomerProfileSignIn />
                    </UserProvider>
                  }
                />
                <Route
                  path="/vendor/TimeFormatSelectionScreen"
                  element={
                    <TimeFormatSelectionScreen themeColor={globalColor} />
                  }
                />
                <Route
                  path="/customer/CustomerProfileMain/CustomerViewAllAddress"
                  element={
                    <UserProvider>
                      <CustomerViewAllAddress themeColor={globalColor} />
                    </UserProvider>
                  }
                />
                <Route
                  path="/vendor/POSHome"
                  element={<POSHomeScreen globalColor={globalColor} />}
                />
                <Route
                  path="/vendor/POSCart"
                  element={
                    <CartProvider>
                      <POSCartScreen />
                    </CartProvider>
                  }
                />
                <Route
                  path="/vendor/POSCheckOut1"
                  element={
                    <CartProvider>
                      <POSCheckoutScreen1 globalColor={globalColor} />
                    </CartProvider>
                  }
                />
                <Route
                  path="/vendor/POSCheckOut2"
                  element={
                    <CartProvider>
                      <POSCheckoutScreen2 />
                    </CartProvider>
                  }
                />
                <Route
                  path="/vendor/POSInvoiceGeneratedSuccess"
                  element={
                    <POSInvoiceGeneratedSuccessScreen
                      mainMessage={"Invoice generated successfully!"}
                      secondaryMessage={
                        "Your invoice has been successfully created. You can now send it to WhatsApp for your customer's convenience."
                      }
                      tickSymbolcolor={"#059669"}
                      navigateBackRoute={"/vendor/POSHome"}
                    />
                  }
                />
                <Route
                  path="/vendor/exportOrdersAsPdf"
                  element={<ExportOrdersAsPdf globalColor={globalColor} />}
                />
                <Route
                  path="/vendor/exportPdfFieldsSelection"
                  element={
                    <ExportPdfFieldsSelection globalColor={globalColor} />
                  }
                />
                <Route
                  path="/vendor/preparingPdf"
                  element={<PreparingPdfInProgressScreen />}
                />
                <Route
                  path="/vendor/pdfExportSuccessOrFailed"
                  element={
                    <PdfGenerationSuccessOrFailureScreen
                      mainMessage={"Download Successfully"}
                      secondaryMessage={
                        "Your data has been successfully exported as a PDF and and save your file successfully."
                      }
                      tickSymbolcolor={"#059669"}
                      navigateBackRoute={"/vendor/allOrders"}
                    />
                  }
                />
                <Route path="/templates/restaurant" element={<Restaurant />} />
                <Route path="/templates/retail" element={<Retail />} />
                <Route path="/templates/grocery" element={<Grocery />} />
                <Route path="/auth/subscriptionConfirmation" element={<SubscriptionConfirmation/>} />
              </Routes>
            </SecureRoute>
          </Suspense>

          <ToastContainer
            autoClose={3000}
            position="top-right"
            style={{ fontFamily: "Poppins" }}
          />
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
